@tailwind base;
@tailwind components;
@tailwind utilities;

.react-calendar {
  border: none !important;
}

.react-calendar__tile--active {
  background: theme("colors.red.800") !important;
  font-weight: 600 !important;
}

.react-calendar__tile--now:not(:disabled) {
  background: theme("colors.white") !important;
}

.react-calendar__tile--hasActive {
  background: theme("colors.red.800") !important;
  font-weight: 600 !important;
  color: theme("colors.white") !important;
}

.react-calendar {
  width: 100% !important;
}

.react-calendar__month-view__weekdays {
  @apply py-2 border-b border-gray-400 bg-gray-50;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
  min-width: 120px !important;
}

.react-calendar__viewContainer {
  @apply overflow-hidden border border-gray-400 rounded;
}

.react-calendar__navigation button[disabled] {
  background-color: #fff !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:focus {
  background: #fff !important;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 1.5rem !important;
  font-weight: 100 !important;
  color: theme("colors.gray.500") !important;
}
